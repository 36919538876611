import * as React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  HStack,
  Tfoot,
} from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import ReactPaginate from 'react-paginate';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table';
import './table.css'
import {useCallback} from "react";
import SearchBar from './SearchBar';

function GlobalFilter({preGlobalFilteredRows,globalFilter,setGlobalFilter,total}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <HStack width={'100%'}>
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search ${count} records...`}
        style={{
          border: '1px solid #CD8764',
          fontSize: '1.1rem',
        }}
      />
     <h1>Users: </h1>
     <h1> {total}</h1>
    </HStack>
  );
}


export default function DataTable({ searchFor , data, setData, columns, sortBy, page , setPage , size ,setSize , total ,pages , setPages , getData}) {

  const handlePageClick = useCallback((e) => {
    console.log('handle page click');

    if (typeof setPage !== 'function') {
      return console.warn('Set page function is not defined!');
    }

    setPage(e.selected + 1)
  }, [setPage]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: sortBy,
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <>
    <SearchBar setData={setData} data={data} searchFor={searchFor} getData={getData}/>
    <Table variant='striped' className='table' size='sm' {...getTableProps()}>
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th {...column.getHeaderProps()} >
                {column.render('Header')}
                <chakra.span {...column.getSortByToggleProps()}>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <TriangleDownIcon aria-label='sorted descending' />
                    ) : (
                      <TriangleUpIcon aria-label='sorted ascending' />
                    )
                  ) : null}
                </chakra.span>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <Td {...cell.getCellProps()} >
                  {cell.render('Cell')}
                </Td>
              ))}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
     <div className="paginator-wrapper">
     <select value={size} name="size" id="" onChange={(e) => setSize(e.target.value)}>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
     <ReactPaginate
        className='paginator'
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={isNaN(pages) ? 0 : Math.round(pages)}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        activeClassName='paginator-active'
        forcePage={isNaN(pages) || isNaN(page) ? 0 : page - 1}
        // forcePage={0}
      />
     </div>
    </>
  );
}
