import { CSVLink } from "react-csv";
import React, { useEffect, useState } from "react";
import { Text, Box, Button, Spinner, Switch } from "@chakra-ui/react";
import { getRejectedUsers, getUsers } from "../../apis/user.js";
import Table from "../common/Table.js";
import cogoToast from "cogo-toast";
import { isObject, isUndefined } from "lodash";
import { acceptMentor, emailMentor } from "../../apis/mentor.js";
import { Empty } from "antd";

const RejectedView = () => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [size, setSize] = React.useState(25);
  const [page, setPage] = React.useState(1);
  const [pages, setPages] = React.useState(1);
  const [total, setTotal] = React.useState(0);

  const onSendEmail = async (r) => {
    setLoading(true);
    const { name, email, mentor_status } = r.values;
    try {
      const res = await emailMentor({ name, email, mentor_status });
      console.log(res);
      console.log(r.values);
    } catch (err) {
      console.log(err);
      cogoToast.error(`Error Sending Email to ${r.values.name}`);
    }
    setLoading(false);
  };
  const onChangeSwitch = async (r) => {
    setLoading(true);
    try {
      const res = await acceptMentor({ email: r.values.email });
      console.log(res);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };
  const onClickDownload = (r) => {
    try {
      window.location = r.values[`docs.cv_doc[0].file`];
    } catch (error) {
      cogoToast.error("User has not uploaded CV");
    }
  };

  const userColumns = [
    {
      Header: "S.No",
      accessor: "_id",
      isNumeric: true,
      Cell: function Index({ row }) {
        return <Text>{row.index + 1}</Text>;
      },
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "CV",
      accessor: "docs.cv_doc",
      Cell: function cv({ row }) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              colorScheme={"blue"}
              onClick={() => onClickDownload(row)}
              isDisabled={
                isUndefined(row.values[`docs.cv_doc`]) &&
                !isObject(row.values[`docs.cv_doc`])
              }
            >
              Download CV
            </Button>
          </div>
        );
      },
    },
    {
      Header: "Approve Applicant",
      accessor: "mentor_status",
      Cell: function approve({ row }) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <Switch
                onChange={() => onChangeSwitch(row)}
                isChecked={row.values.mentor_status === 2}
              />
            )}
          </div>
        );
      },
    },
    {
      Header: "Send Rejection Email",
      accessor: "mentor.reject_email",
      Cell: function email({ row }) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <Switch
                isDisabled={row.values[`mentor.reject_email`]}
                onChange={() => onSendEmail(row)}
                isChecked={row.values[`mentor.reject_email`]}
              />
            )}
          </div>
        );
      },
    },
    {
      Header: "Rejected At",
      accessor: "mentor.rejected_at",
      Cell: function status({ row }) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <Text>
                {!isUndefined(row.values[`mentor.rejected_at`])
                  ? `${new Date(
                      row.values[`mentor.rejected_at`]
                    ).toLocaleDateString()}`
                  : null}
              </Text>
            )}
          </div>
        );
      },
    },
  ];
  const getData = async () => {
    setPageLoad(true);
    try {
      const data = { page, size };
      const { rejects } = await getRejectedUsers(data);
      console.log(rejects);
      setTotal(rejects.total);
      setPages(rejects.pages);
      setPage(page);
      setSize(rejects.limit);
      setStats(rejects.docs);
    } catch (err) {
      console.log(err);
    }
    setPageLoad(false);
  };

  useEffect(() => {
    getData();
  }, [loading]);

  useEffect(() => {
    getData();
  }, [page, size]);

  const headers = [
    { label: "Name", key: "first_name" },
    { label: "Email", key: "email" },
    { label: "Date of birth", key: "dob" },
    { label: "Contact Number", key: "contact_number" },
    { label: "College Name", key: "college_name" },
    { label: "Degree", key: "degree" },
    { label: "Speacialization", key: "specialization" },
    { label: "Year of completion", key: "yoc" },
    { label: "Company", key: "company" },
    { label: "Current Location", key: "current_location" },
    { label: "Ethnicity", key: "ethnicity" },
    { label: "Any Disabilities", key: "disabilities" },
    { label: "Nationality", key: "nationality" },
    { label: "Vedio Analysis Score", key: "va_score" },
  ];

  const csvReport = {
    filename: "Report.csv",
    headers: headers,
    data: stats,
  };
  return (
    <div>
      {pageLoad ? (
        <Spinner />
      ) : (
        <>
          <Box>
            <Table
              data={stats}
              columns={userColumns}
              sortBy={"mentor.rejected_at"}
              page={page}
              setPage={setPage}
              size={size}
              setSize={setSize}
              total={total}
              pages={pages}
              setPages={setPages}
              getData={getData}
              searchFor={3}
              setData={setStats}
            />
          </Box>
          <Button size="lg" colorScheme="green" mt="24px">
            <CSVLink {...csvReport}>Download Report</CSVLink>
          </Button>
        </>
      )}
    </div>
  );
};

export default RejectedView;
