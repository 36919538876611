import * as React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  chakra,
} from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  useTable,
  useRowSelect,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table';
import { Checkbox } from 'antd';
import './table.css'
import ReactPaginate from 'react-paginate';
import SearchBar from './SearchBar';

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  total
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <HStack>
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search ${count} records...`}
        style={{
          border: '1px solid #000',
          fontSize: '1.1rem',
        }}
      />
      <h1>Total:</h1>
      <h1>{total}</h1>
    </HStack>
  );
}
export const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <Checkbox type='checkbox' ref={resolvedRef} {...rest} />;
  }
);

export default function DataTable({
  data,
  columns,
  onRowSelect,
  sortBy,
   page , setPage , size ,setSize , total ,pages , setPages,
   getData,
                searchFor,
                setData,
  ...rest
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      initialState: {
        sortBy: [
          {
            id: sortBy,
            desc: false,
          },
        ],
      },
      data,
    },
    useGlobalFilter,
    useSortBy,
    useRowSelect
  );
  React.useEffect(() => {
    onRowSelect(selectedFlatRows);
  }, [onRowSelect, selectedFlatRows]);

 
  const handlePageClick = (e) => {
    setPage(e.selected + 1)
  }

  return (
    <>
    <SearchBar setData={setData} data={data} searchFor={searchFor} getData={getData}/>
    <Table variant='striped' className='ApplicantsTable' size='xs'  {...getTableProps()} {...rest}>
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                // onClick={() => handleMultiSortBy(column, setSortBy)}
              >
                {column.render('Header')}
                <chakra.span pl='4'>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <TriangleDownIcon aria-label='sorted descending' />
                    ) : (
                      <TriangleUpIcon aria-label='sorted ascending' />
                    )
                  ) : null}
                </chakra.span>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric}>
                  {cell.render('Cell')}
                </Td>
              ))}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
    <div className="paginator-wrapper">
    <select value={size} name="size" id="" onChange={(e) => setSize(e.target.value)}>
       <option value="25">25</option>
       <option value="50">50</option>
       <option value="100">100</option>
     </select>
    <ReactPaginate
       className='paginator'
       breakLabel="..."
       nextLabel="next >"
       onPageChange={handlePageClick}
       pageRangeDisplayed={5}
       pageCount={pages}
       previousLabel="< previous"
       renderOnZeroPageCount={null}
       activeClassName='paginator-active'
       forcePage={page - 1}
     />
    </div>
    </>
  );
}
