import React from 'react';
import { Switch } from 'react-router-dom';
import {
  BsPersonFill,
  BsPersonPlusFill,
  BsPersonDashFill,
  BsPersonLinesFill,
} from 'react-icons/bs';
import { BiMessageAltDetail } from 'react-icons/bi';
import { AiOutlineDashboard } from 'react-icons/ai';
import PrivateRoute from '../common/PrivateRoute';
import DashboardView from '../dashboard/DashboardView';
import UserView from '../user/UserView';
import MentorView from '../mentor/MentorView';
import ContactView from '../contact/ContactView';
import ApplicantView from '../applicants/ApplicantView';
import RejectedView from '../rejected/RejectedView';
import DisabledView from '../disabled/DisabledView';

export const routes = [
  {
    name: 'Dashboard',
    path: '/',
    icon: AiOutlineDashboard,
  },
  {
    name: 'Contact',
    path: '/contact',
    icon: BiMessageAltDetail,
  },
  {
    name: 'Users',
    path: '/users',
    icon: BsPersonFill,
  },
  {
    name: 'Applicants',
    path: '/applicants',
    icon: BsPersonPlusFill,
  },
  {
    name: 'Rejected',
    path: '/rejected',
    icon: BsPersonDashFill,
  },
  {
    name: 'Mentors',
    path: '/mentors',
    icon: BsPersonLinesFill,
  },
  {
    name: 'Disabled',
    path: '/disabled',
    icon: BsPersonDashFill,
  },
];

const CoreRoutes = () => {
  return (
    <Switch>
      <PrivateRoute exact path='/'>
        <DashboardView />
      </PrivateRoute>
      <PrivateRoute exact path='/contact'>
        <ContactView />
      </PrivateRoute>
      <PrivateRoute exact path='/users'>
        <UserView />
      </PrivateRoute>
      <PrivateRoute exact path='/applicants'>
        <ApplicantView />
      </PrivateRoute>
      <PrivateRoute exact path='/rejected'>
        <RejectedView />
      </PrivateRoute>
      <PrivateRoute exact path='/mentors'>
        <MentorView />
      </PrivateRoute>
      <PrivateRoute exact path='/disabled'>
        <DisabledView />
      </PrivateRoute>
    </Switch>
  );
};

export default CoreRoutes;
