import {
  Heading,
  SimpleGrid,
  Spinner,
  useColorModeValue,
  Box as Boxy,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import Box from "../common/DashboardBox.js";
import cogoToast from "cogo-toast";
import { getDashboardCount, getUsers } from "../../apis/user.js";
let DashboardView = () => {
  const [candidateCount, setCandidateCount] = useState("");
  const [mentorCount, setMentorCount] = useState("");
  const [applicantCount, setApplicantCount] = useState("");
  const [rejectedCount, setRejectedCount] = useState("");
  const [userCount, setUserCount] = useState("");
  const [loading, setLoading] = useState(false);

  const getDataCounts = async () => {
    setLoading(true);
    try {
      const dataCount = await getDashboardCount();
      setUserCount(dataCount.users);
      setMentorCount(dataCount.mentors);
      setCandidateCount(dataCount.candidates);
      setApplicantCount(dataCount.applicants);
      setRejectedCount(dataCount.rejects);
    } catch (error) {
      cogoToast.error(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getDataCounts();
  }, []);

  return (
    <Boxy>
      <Heading color={useColorModeValue("black", "white")}>
        Welcome to the Admin Dashboard
      </Heading>
      <SimpleGrid columns={3} columnGap={"15px"} mt={"30px"}>
        <Box
          count={loading ? <Spinner /> : userCount}
          name={"Users"}
          url={"users"}
        />
        <Box
          count={loading ? <Spinner /> : candidateCount}
          name={"Candidates"}
          url={"users"}
        />
        <Box
          more
          name={"Mentors"}
          count={loading ? <Spinner /> : mentorCount}
          secondName={"Applicants"}
          secondCount={loading ? <Spinner /> : applicantCount}
          thirdName={"Rejected Applicants"}
          thirdCount={loading ? <Spinner /> : rejectedCount}
          url={"mentors"}
        />
      </SimpleGrid>
    </Boxy>
  );
};

export default DashboardView;
