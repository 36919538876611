import React, { useEffect, useState } from "react";
import { Text, Box, Spinner, Button, Switch } from "@chakra-ui/react";
import { getUsers, getUsersDetails } from "../../apis/user.js";
import cogoToast from "cogo-toast";
import Table from "../common/Table.js";
import { isUndefined } from "lodash";
import {
  acceptMentor,
  rejectMentor,
  deleteMentorApplicantAPI,
} from "../../apis/mentor.js";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

const UserView = () => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentRow, setCurrentRow] = useState();
  const [size, setSize] = React.useState(25);
  const [page, setPage] = React.useState(1);
  const [pages, setPages] = React.useState(1);
  const [total, setTotal] = React.useState(0);

  const onChangeSwitch = async (r) => {
    setLoading(true);
    if (r.values.mentor_status > 2 || r.values.mentor_status < 2) {
      try {
        const res = await acceptMentor({ email: r.values.email });
        console.log(res);
      } catch (err) {
        console.log(err);
      }
    } else if (r.values.mentor_status === 2) {
      try {
        const res = await rejectMentor({ email: r.values.email });
        console.log(res);
      } catch (err) {
        console.log(err);
      }
    }
    setLoading(false);
  };
  const getData = async () => {
    setLoading(true);
    try {
      const data = { page, size };
      const { users } = await getUsersDetails(data);
      console.log(users);
      setTotal(users.total);
      setPages(users.pages);
      setPage(page);
      setSize(users.limit);
      setStats(users.docs);
    } catch (err) {
      cogoToast.error(err.message);
    }
    setLoading(false);
  };

  const deleteMentorApplicant = async (rowData) => {
    console.log(rowData);
    console.log(stats);
    let user_id = await stats.filter((val) => {
      if (val.email === rowData.values.email) {
        return val._id;
      }
    });

    let data = {
      userId: user_id[0]._id,
    };
    try {
      const res = await deleteMentorApplicantAPI({ data });
      console.log(res);
      cogoToast.success(`User ${rowData.values.email} Deleted Successfully`);
      onClose();
      setChange(user_id[0]._id);
    } catch (err) {
      cogoToast.error(err.message);
    }
  };

  useEffect(() => {
    getData();
  }, [change]);

  useEffect(() => {
    getData();
  }, [page, size]);

  const onClickDownload = (r) => {
    try {
      window.location = r.values[`docs.cv_doc[0].file`];
    } catch (error) {
      cogoToast.error("User has not uploaded CV");
    }
  };
  const userColumns = [
    {
      Header: "S.No",
      accessor: "_id",
      isNumeric: true,
      Cell: function Index({ row }) {
        return <Text>{size - row.index}</Text>;
      },
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Verified",
      accessor: "verified",
      Cell: function approve({ row }) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <Switch
                isReadOnly
                onChange={() => onChangeSwitch(row)}
                isChecked={row.values.verified}
              />
            )}
          </div>
        );
      },
    },
    {
      Header: "CV",
      accessor: "docs.cv_doc[0].file",
      Cell: function cv({ row }) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              colorScheme={"blue"}
              onClick={() => onClickDownload(row)}
              isDisabled={isUndefined(row.values[`docs.cv_doc[0].file`])}
            >
              Download CV
            </Button>
          </div>
        );
      },
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      Cell: function status({ row }) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <Text>
                {!isUndefined(row.values[`createdAt`])
                  ? `${new Date(row.values[`createdAt`]).toLocaleDateString()}`
                  : null}
              </Text>
            )}
          </div>
        );
      },
    },
    {
      Header: "Delete Applicant",
      accessor: "delete_applicant",
      Cell: function deleteApplicant({ row }) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <Button
                colorScheme={"red"}
                onClick={() => {
                  setCurrentRow(row);
                  onOpen();
                }}
              >
                Delete
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Box>
      {loading ? (
        <Spinner />
      ) : (
        <Table
          data={stats}
          setData={setStats}
          columns={userColumns}
          page={page}
          setPage={setPage}
          size={size}
          setSize={setSize}
          total={total}
          pages={pages}
          setPages={setPages}
          getData={getData}
          searchFor={0}
        />
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are You Sure You Want To Delete This User</ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                deleteMentorApplicant(currentRow);
              }}
            >
              {" "}
              Yes{" "}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default UserView;
