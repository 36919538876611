import { Button, HStack, Input } from '@chakra-ui/react';
import { Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { doSearch } from '../../apis/common';

const SearchBar = ({ searchFor , setData ,data , getData }) => {


  const [value, setValue] = useState('')

    const handleSearchChange = async (e) => {
        setValue(e.target.value)
        try {
          const resData = await doSearch({queryFor : searchFor , text : e.target.value})
          setData(resData.data)

        } catch (error) {
          console.log("error : " , error);
        }
    }

    const handleReload = async () => {
      setValue('')
      await getData()
    }

    const findSearchFor = (num) => {
      switch (num) {
        case 0:
          return'Users'
        case 1:
          return'Applicants'
        case 2:
          return'Mentors'
        case 3:
          return'Rejected'
        default:
          break;
      }
    }


  return (
    <HStack width={'100%'} marginTop={3} marginBottom={3}>
      <Input
        value={value || ''}
        onChange={(e) => handleSearchChange(e)}
        placeholder={`Search`}
      />
      <Typography>{findSearchFor(searchFor)}</Typography>
      <Button size={'lg'} onClick={handleReload}>Reload List</Button>
    </HStack>
  )
}

export default SearchBar