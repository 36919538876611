import React from 'react';
import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Stack,
  Box,
  FormLabel,
  Input,
  Select,
  DrawerFooter,
  FormControl,
} from '@chakra-ui/react';
import { isUndefined } from 'lodash';
import cogoToast from 'cogo-toast';
import { findUserByEmail, uploadCV, createApplicant } from '../../apis/user';
import { signup } from '../../apis/auth';
import { acceptMentor } from '../../apis/mentor';

export default function MentorDrawer(props) {
  const [values, setValues] = React.useState({
    name: '',
    email: '',
    i_am: 'Student',
    field: '',
    cv: '',
    industry: '',
  });
  const checkUser = async () => {
    const { email } = values;
    if (!isUndefined(email)) {
      try {
        const res = await findUserByEmail({ email });
        if (res.length > 0) {
          if (res[0].mentor_status < 2) {
            return res[0];
          } else if (res[0].mentor_status === 2) {
            cogoToast.error('Applicant is already a mentor');
          } else if (res[0].mentor_status === 3) {
            cogoToast.error('Applicant has been rejected');
          } else if (res[0].mentor_status === 4) {
            cogoToast.error('Applicant has been disabled');
          }
        } else {
          const temp = Math.random().toString(36).slice(2);
          const data = {
            name: values.name,
            email: values.email,
            password: temp,
            role: 1,
          };
          const d = await signup({ data });
          return d.data;
        }
      } catch (error) {
        cogoToast.info(error.message);
        return false;
      }
    } else {
      cogoToast.error('Please fill email');
      return false;
    }
  };

  const onChangeInput = async (field, e) => {
    if (field === 'cv') {
      setValues((prev) => ({ ...prev, [field]: e.target.files[0] }));
    } else {
      setValues((prev) => ({ ...prev, [field]: e.target.value }));
    }
  };
  const handleValidation = () => {
    if (
      values.name === '' ||
      values.email === '' ||
      values.field === '' ||
      values.industry === '' 
    ) {
      cogoToast.error(`Please fill in all fields`);
      return false;
    } else {
      return true;
    }
  };
  const onSubmit = async () => {
    if (handleValidation()) {
      const user = await checkUser();
      const formData = new FormData();
      if (user._id) {
        console.log(true, user);
        formData.append('user_id', user._id);
      } else {
        console.log(false, user);
        formData.append('user_id', user.user_id);
      }
      if(values.cv){
        formData.append('cv_doc', values.cv);
      }
      try {
      if(values.cv){ 
        const res = await uploadCV(formData);
        var filedata = {
          cv_doc: res.item.cv_doc,
        };
      }
        const data = {
          name: values.name,
          email: values.email,
          docs: filedata && filedata,
          industry: values.industry,
          field: values.field,
          i_am: values.i_am,
          user_id: isUndefined(user._id) ? user.user_id : user._id,
          byAdmin : true
        };
        const resp = await createApplicant(data);
        if (resp.mentor_status === 1) {
          await acceptMentor({ email: values.email });
          cogoToast.success(`Successfully Created ${data.name} as Mentor`);
          onClose();
        } else {
          cogoToast.error(`Error`);
        }
      } catch (error) {
        console.log(error);
        cogoToast.error(error.message);
      }
    }
  };
  const { isOpen, onClose } = props;
  const firstField = React.useRef();
  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      size={'lg'}
      initialFocusRef={firstField}
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth='1px'>Create a new mentor</DrawerHeader>
        <DrawerBody>
          <Stack spacing='24px'>
            <Box>
              <FormControl isRequired>
                <FormLabel htmlFor='name'>Name</FormLabel>
                <Input
                  onChange={(e) => onChangeInput('name', e)}
                  ref={firstField}
                  id='name'
                  placeholder='Please enter user name'
                />
              </FormControl>
            </Box>

            <Box>
              <FormControl isRequired>
                <FormLabel htmlFor='email'>Email</FormLabel>
                <Input
                  onChange={(e) => onChangeInput('email', e)}
                  type='email'
                  id='email'
                  placeholder='Please enter email'
                />
              </FormControl>
            </Box>

            <Box>
              <FormControl isRequired>
                <FormLabel htmlFor='i_am'>Select I Am</FormLabel>
                <Select
                  id='i_am'
                  defaultValue='Student'
                  onChange={(e) => onChangeInput('i_am', e)}
                >
                  <option value='Student'>Student</option>
                  <option value='Working Professional'>
                    Working Professional
                  </option>
                </Select>
              </FormControl>
            </Box>

            <Box>
              <FormControl isRequired>
                <FormLabel htmlFor='fieldOf'>Field of Work/Study</FormLabel>
                <Input
                  onChange={(e) => onChangeInput('field', e)}
                  type='text'
                  id='fieldOf'
                  placeholder='Please enter field of work/study'
                />
              </FormControl>
            </Box>
            <Box>
              <FormControl isRequired>
                <FormLabel htmlFor='industry'>Industry</FormLabel>
                <Input
                  onChange={(e) => onChangeInput('industry', e)}
                  type='text'
                  id='industry'
                  placeholder='Please enter industry'
                />
              </FormControl>
            </Box>
            <Box>
              <FormControl>
                <FormLabel htmlFor='cv'>CV</FormLabel>
                <Input
                  onChange={(e) => {
                    onChangeInput('cv', e);
                  }}
                  type={'file'}
                  id='industry'
                  accept='application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  placeholder='Please upload CV'
                  border={'none'}
                />
              </FormControl>
            </Box>
          </Stack>
        </DrawerBody>

        <DrawerFooter borderTopWidth='1px'>
          <Button variant='outline' mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme='blue' onClick={onSubmit}>
            Submit
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
