import { CSVLink } from "react-csv";
import React, { useEffect, useState } from "react";
import { Text, Box, Button, Spinner, Switch } from "@chakra-ui/react";
import Table from "../common/Table.js";
import { acceptMentor } from "../../apis/mentor.js";
import { getUsers } from "../../apis/user.js";
import { Empty } from "antd";
const DisabledView = () => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const onChangeSwitch = async (r) => {
    setLoading(true);
    try {
      const res = await acceptMentor({ email: r.values.email });
      console.log(res);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };
  const userColumns = [
    {
      Header: "S.No",
      accessor: "index",
      isNumeric: true,
      Cell: function Index({ row }) {
        return <Text>{row.index + 1}</Text>;
      },
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Disable/Enable Mentor",
      accessor: "mentor_status",
      Cell: function reject({ row }) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <Switch
                onChange={() => onChangeSwitch(row)}
                isChecked={row.values.mentor_status === 2}
              />
            )}
          </div>
        );
      },
    },
  ];
  const getData = async () => {
    try {
      const res = await getUsers();
      const r = res.filter((r) => r.mentor_status === 4);
      setStats(r);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, [loading]);

  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "status", key: "mentor_status" },
  ];

  const csvReport = {
    filename: "Report.csv",
    headers: headers,
    data: stats,
  };
  if (stats.length === 0) {
    return <Empty />;
  }
  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <Box>
        <Table data={stats} columns={userColumns} />
      </Box>
      <Button size="lg" colorScheme="green" mt="24px">
        <CSVLink {...csvReport}>Download Report</CSVLink>
      </Button>
    </div>
  );
};

export default DisabledView;
