import React from "react";
import {
    useTable,
    useResizeColumns,
    useFlexLayout,
    useRowSelect,
    useGlobalFilter,
    useAsyncDebounce,
    usePagination
} from "react-table";
import { HStack, Table } from "@chakra-ui/react";

const headerProps = (props, { column }) => getStyles(props, column.align);

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

const getStyles = (props, align = "left") => [
    props,
    {
        style: {
            justifyContent: align === "right" ? "flex-end" : "flex-start",
            alignItems: "flex-start",
            display: "flex"
        }
    }
];

export default function TestTable({ columns, data, onRowSelect }) {
    const defaultColumn = React.useMemo(
        () => ({
            // When using the useFlexLayout:
            minWidth: 30, // minWidth is only used as a limit for resizing
            width: 150, // width is used for both the flex-basis and flex-grow
            maxWidth: 200 // maxWidth is only used as a limit for resizing
        }),
        []
    );

    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }) {
        const count = preGlobalFilteredRows.length;
        const [value, setValue] = React.useState(globalFilter);
        const onChange = useAsyncDebounce((value) => {
            setGlobalFilter(value || undefined);
        }, 200);

        return (
            <HStack>
                Search:
                <input
                    value={value || ''}
                    onChange={(e) => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`Search ${count} records...`}
                    style={{
                        border: '1px solid #000',
                        fontSize: '1.1rem',
                    }}
                />
            </HStack>
        );
    }

    const {
        getTableProps,
        headerGroups,
        page,
        prepareRow,
        selectedFlatRows,
        getTableBodyProps,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,

        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { pageIndex: 0 }
        },
        useResizeColumns,
        useFlexLayout,
        useGlobalFilter,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.allColumns.push((columns) => [
                // Let's make a column for selection
                // {
                //   id: "selection",
                //   disableResizing: true,
                //   minWidth: 35,
                //   width: 35,
                //   maxWidth: 35,
                //   // The header can use the table's getToggleAllRowsSelectedProps method
                //   // to render a checkbox
                //   Header: ({ getToggleAllRowsSelectedProps }) => (
                //     <div>
                //       <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                //     </div>
                //   ),
                //   // The cell can use the individual row's getToggleRowSelectedProps method
                //   // to the render a checkbox
                //   Cell: ({ row }) => (
                //     <div>
                //       <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                //     </div>
                //   )
                // },
                ...columns
            ]);
            hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
                // fix the parent group of the selection button to not be resizable
                const selectionGroupHeader = headerGroups[0].headers[0];
                selectionGroupHeader.canResize = false;
            });
        }
    );

    React.useEffect(() => {
        onRowSelect(selectedFlatRows);
    }, [onRowSelect, selectedFlatRows]);

    return (
        <>
            <div className="pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>{' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>{' '}
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>{' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>{' '}
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <span>
                    | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                </span>{' '}
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
            <Table variant='striped' {...getTableProps()} className="table">
                <div>
                    <div className="tr">
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={state.globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />
                    </div>
                    {headerGroups.map((headerGroup) => (
                        <div
                            {...headerGroup.getHeaderGroupProps({
                                // style: { paddingRight: '15px' },
                            })}
                            className="tr"
                        >
                            {headerGroup.headers.map((column) => (
                                <div {...column.getHeaderProps(headerProps)} className="th">
                                    {column.render("Header")}
                                    {/* Use column.getResizerProps to hook up the events correctly */}
                                    {column.canResize && (
                                        <div
                                            {...column.getResizerProps()}
                                            className={`resizer ${column.isResizing ? "isResizing" : ""
                                                }`}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="tbody">
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <div {...row.getRowProps()} className="tr">
                                {row.cells.map((cell) => {
                                    return (
                                        <div {...cell.getCellProps(cellProps)} className="td">
                                            {cell.render("Cell")}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </Table>
        </>
    );
}
