import React from 'react';
import { Flex } from '@chakra-ui/layout';
import styled from 'styled-components';
import Sidebar from './CoreSidebar';

const CoreLayout = (props) => {
  const { children } = props;
  return (
    <Flex w='100%' direction='row'>
      <Sidebar />
      <Flex p={16}>
        <CoreBody>{children}</CoreBody>
      </Flex>
    </Flex>
  );
};

export default CoreLayout;

const CoreBody = styled.div`
  z-index: 10;
  flex: 1;
`;
