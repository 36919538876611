export const API_SERVER = process.env.REACT_APP_ADMIN_BACKEND;
export const UPLOAD_SERVER = process.env.REACT_APP_UPLOAD_SERVER;
export const BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER;
export const MENTOR_STATUS = [
  'Not Applied',
  'Pending',
  'Approved',
  'Rejected',
  'Disabled',
];
