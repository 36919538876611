import { CSVLink } from "react-csv";
import React, { useEffect, useState } from "react";
import {
  Text,
  Box,
  Button,
  Spinner,
  HStack,
  Switch,
  useDisclosure,
} from "@chakra-ui/react";
import Table from "../common/Table.js";
import { AddIcon } from "@chakra-ui/icons";
import { isUndefined } from "lodash";
import {
  rejectMentor,
  emailMentor,
  mentorAccess,
  getAllMentors,
  disableMentor,
  generateDashboardToken,
  getMentors,
} from "../../apis/mentor.js";
import cogoToast from "cogo-toast";
import MentorDrawer from "./MentorDrawer.js";
const MentorView = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [size, setSize] = React.useState(25);
  const [page, setPage] = React.useState(1);
  const [pages, setPages] = React.useState(1);
  const [total, setTotal] = React.useState(0);
  const [soNo, setsoNo] = React.useState(1);
  const onChangeVisibility = async (r) => {
    setLoading(true);
    try {
      const res = await mentorAccess({
        param: "profile",
        email: r.values.email,
        value: !r.values[`mentor.is_profile_visible`],
      });
      console.log(res);
    } catch (err) {
      console.log(err);
      cogoToast.error(`Error Making ${r.values.name}'s Profile Visible`);
    }
    setLoading(false);
  };
  const onSendEmail = async (r) => {
    setLoading(true);
    const { name, email, mentor_status, _id: mentorId } = r.values;
    try {
      const token = await generateDashboardToken({ email, mentorId });
      const link = `${process.env.REACT_APP_MENTOR_APPLICATION_BASE}/mentor-dashboard/${token}`;
      const res = await emailMentor({ name, email, mentor_status, link });
      console.log(res);
    } catch (err) {
      console.log(err);
      cogoToast.error(`Error Sending Email to ${r.values.name}`);
    }
    setLoading(false);
  };
  const onChangeSwitch = async (r) => {
    setLoading(true);
    try {
      const res = await rejectMentor({ email: r.values.email });
      console.log(res);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };
  const onChangeDisabled = async (r) => {
    setLoading(true);
    try {
      const res = await disableMentor({ email: r.values.email });
      console.log(res);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };
  const userColumns = [
    {
      Header: "S.No",
      accessor: "_id",
      isNumeric: true,
      Cell: function Index({ row }) {
        return <Text>{size - row.index}</Text>;
      },
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Reject/Approve Mentor",
      Cell: function reject({ row }) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <Switch
                onChange={() => onChangeSwitch(row)}
                isChecked={row.values.mentor_status === 2}
              />
            )}
          </div>
        );
      },
    },
    {
      Header: "Send Approval",
      accessor: "mentor.accept_email",
      Cell: function email({ row }) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <Switch
                isDisabled={row.values[`mentor.accept_email`]}
                onChange={() => onSendEmail(row)}
                isChecked={row.values[`mentor.accept_email`]}
              />
            )}
          </div>
        );
      },
    },
    {
      Header: "Profile Visibility",
      accessor: "mentor.is_profile_visible",
      Cell: function reject({ row }) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <Switch
                onChange={() => onChangeVisibility(row)}
                isChecked={row.values[`mentor.is_profile_visible`]}
              />
            )}
          </div>
        );
      },
    },
    {
      Header: "Disabled",
      accessor: "mentor_status",
      Cell: function reject({ row }) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <Switch
                onChange={() => onChangeDisabled(row)}
                isChecked={row.values[`mentor_status`] === 4}
              />
            )}
          </div>
        );
      },
    },
    {
      Header: "Approved At",
      accessor: "mentor.approved_at",
      Cell: function status({ row }) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <Text>
                {!isUndefined(row.values[`mentor.approved_at`])
                  ? row.values[`mentor.approved_at`]
                  : null}
              </Text>
            )}
          </div>
        );
      },
    },
    {
      Header: "Profile Complete",
      accessor: "mentor.profile_percentage",
      Cell: function status({ row }) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text>{row.values[`mentor.profile_percentage`]}%</Text>
          </div>
        );
      },
    },
  ];
  const getData = async () => {
    try {
      const data = { page, size };
      const { mentors } = await getMentors(data);
      console.log(mentors);
      setTotal(mentors.total);
      setPages(mentors.pages);
      setPage(page);
      setSize(mentors.limit);
      setStats(mentors.docs);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, [loading, !isOpen]);

  useEffect(() => {
    getData();
  }, [page, size]);

  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "status", key: "mentor_status" },
  ];

  const csvReport = {
    filename: "Report.csv",
    headers: headers,
    data: stats,
  };

  return (
    <div>
      <HStack align="flex-end" justify={"flex-end"}>
        <Button
          size="lg"
          colorScheme="blue"
          leftIcon={<AddIcon />}
          mt="24px"
          onClick={onOpen}
        >
          Add Mentor
        </Button>
        <MentorDrawer isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      </HStack>
      <Box>
        <Table
          data={stats}
          columns={userColumns}
          page={page}
          setPage={setPage}
          size={size}
          setSize={setSize}
          total={total}
          pages={pages}
          setPages={setPages}
          getData={getData}
          searchFor={2}
          setData={setStats}
        />
      </Box>
      <Button size="lg" colorScheme="green" mt="24px">
        <CSVLink {...csvReport}>Download Report</CSVLink>
      </Button>
    </div>
  );
};

export default MentorView;
