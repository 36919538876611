import React, { useEffect, useState } from "react";
import { Center, Text, Box, Spinner } from "@chakra-ui/react";
import Table from "../common/Table.js";
import { Empty } from "antd";
import cogoToast from "cogo-toast";
import { getContactData } from "../../apis/contact.js";

const contactColumns = [
  {
    Header: "S. No",
    accessor: "index",
    isNumeric: true,
    Cell: function Index({ row }) {
      return <Text>{row.index + 1}</Text>;
    },
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Subject",
    accessor: "subject",
  },
  {
    Header: "Message",
    accessor: "message",
  },
];

const ContactView = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await getContactData();
      setData(res.data);
    } catch (error) {
      cogoToast.error(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (data === undefined || data.length === 0) {
    return (
      <Center>
        <Empty />
      </Center>
    );
  }

  if (loading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <Box>
      <Table data={data} columns={contactColumns} />
    </Box>
  );
};

export default ContactView;
