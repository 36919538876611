import { Heading, Button, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Box = (props) => {
  const headingColor = useColorModeValue('black', 'white');
  return (
    <Container>
      <Heading as='h3' size='lg' color={headingColor}>
        {props.name}
      </Heading>
      <Heading as='h4' size='md' color={headingColor}>
        Total No. Of {props.name} are {props.count}
      </Heading>
      {props.more && (
        <>
          <Heading as='h4' size='md' color={headingColor}>
            Total No. Of {props.secondName} are {props.secondCount}
          </Heading>
          <Heading as='h4' size='md' color={headingColor}>
            Total No. Of {props.thirdName} are {props.thirdCount}
          </Heading>
        </>
      )}
      <Link to={`/${props.url}`}>
        <Button size='lg' colorScheme='green' mt='24px'>
          Show All {props.name}
        </Button>
      </Link>
    </Container>
  );
};

export default Box;

const Container = styled.div`
  margin-top: 10%;
  border: 2px solid;
  width: fit-content;
  padding: 1rem;
`;
