import React, { useState } from 'react';
import {
  Flex,
  IconButton,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  useColorMode,
  useToast,
  MenuList,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { FiMenu } from 'react-icons/fi';
import NavItem from '../common/NavItem';
import { routes } from './CoreRoutes';
import { SunIcon, MoonIcon } from '@chakra-ui/icons';
import cookie from 'js-cookie';
import { useHistory } from 'react-router-dom';

export default function Sidebar() {
  const [navSize, changeNavSize] = useState('large');
  const { colorMode, toggleColorMode } = useColorMode();
  const toast = useToast();
  const token = cookie.get('adminToken');
  const history = useHistory();
  const MenuBackground = useColorModeValue('gray.100', 'gray.900');
  const onClickLogout = () => {
    if (typeof window !== 'undefined') {
      localStorage.removeItem('dbStatus')
      cookie.remove('adminToken');
      history.push('/signin');
      window.location.reload();
      toast({
        title: 'Successfully Logged Out',
        status: 'success',
        variant: 'subtle',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const onClickLogin = () => {
    history.push('/login');
  };
  return (
    <Flex
      transition='0.7s ease'
      pos='relative'
      left='5px'
      marginTop='2.5vh'
      boxShadow='0 4px 12px 0 rgba(0, 0, 0, 0.05)'
      w={navSize === 'small' ? '100px' : '300px'}
      flexDir='column'
      background={'white'}
      justifyContent='space-between'
    >
      <VStack
        p='8px'
        w='130px'
        spacing={6}
        alignItems={navSize === 'small' ? 'center' : 'flex-start'}
        as='nav'
      >
        <IconButton
          background='none'
          mt={5}
          _hover={{ background: 'none' }}
          icon={<FiMenu />}
          onClick={() => {
            if (navSize === 'small') changeNavSize('large');
            else changeNavSize('small');
          }}
        />
        {routes.map((r) => (
          <NavItem
            key={r.name}
            navSize={navSize}
            title={r.name}
            href={r.path}
            icon={r.icon}
          />
        ))}
        <Menu>
          <MenuButton
            as={Button}
            rounded={'full'}
            variant={'link'}
            cursor={'pointer'}
          >
            <Avatar size={'sm'} name={'Admin'} />
          </MenuButton>
          <MenuList background={MenuBackground} zIndex={100}>
            {token ? (
              <MenuItem onClick={onClickLogout}>Logout</MenuItem>
            ) : (
              <MenuItem onClick={onClickLogin}>Login</MenuItem>
            )}
            <MenuDivider />
            <MenuItem onClick={toggleColorMode}>
              {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
            </MenuItem>
          </MenuList>
        </Menu>
      </VStack>
    </Flex>
  );
}
