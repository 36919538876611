import { CSVLink } from "react-csv";
import React, { useEffect, useState, useMemo } from "react";
import {
  Text,
  Box,
  Button,
  Spinner,
  HStack,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon, RepeatIcon } from "@chakra-ui/icons";
import { getAppliedUsers, getUsers } from "../../apis/user.js";
import Tables, { IndeterminateCheckbox } from "../common/CheckboxTable.js";
import cogoToast from "cogo-toast";
import { isNull, isUndefined, map } from "lodash";
import { acceptMentor, rejectMentor } from "../../apis/mentor.js";
import { Empty } from "antd";
import ApplicantDrawer from "./ApplicantDrawer.js";
import styled from "styled-components";
import TestTable from "../common/testTable";
import Table from "../common/Table.js";

const Styles = styled.div`
  padding: 1rem;
  ${
    "" /* These styles are suggested for the table fill all available space in its containing element */
  }
  display: block;
  ${
    "" /* These styles are required for a horizontaly scrollable table overflow */
  }
  overflow: auto;

  .table {
    border-spacing: 0;

    .thead {
      ${
        "" /* These styles are required for a scrollable body to align with the header properly */
      }
      overflow-y: auto;
      overflow-x: hidden;
    }

    .tbody {
      ${"" /* These styles are required for a scrollable table body */}
      overflow-y: scroll;
      overflow-x: hidden;
      height: 550px;
    }

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;

      ${
        "" /* In this example we use an absolutely position resizer,
       so this is required. */
      }
      position: relative;

      :last-child {
        border-right: 0;
      }

      .resizer {
        right: 0;
        background: blue;
        width: 10px;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        ${"" /* prevents from scrolling while dragging on touch devices */}
        touch-action :none;

        &.isResizing {
          background: red;
        }
      }
    }
    .pagination {
      padding: 0.5rem;
      padding-bottom: 70px;
    }
  }
`;

const ApplicantView = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [size, setSize] = useState(25);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [total, setTotal] = useState(0);
  const approveApplicant = async (r) => {
    setLoading(true);
    try {
      await acceptMentor({ email: r.values.email });
    } catch (err) {
      console.log(err);
      cogoToast.error(err.message);
    }

    setLoading(false);
  };
  const rejectApplicant = async (r) => {
    setLoading(true);
    try {
      await rejectMentor({ email: r.values.email });
    } catch (err) {
      console.log(err);
      cogoToast.error(err.message);
    }
    setLoading(false);
  };
  const onClickDownload = (r, u) => {
    try {
      window.location = r.values[`docs.cv_doc.file`];
    } catch (error) {
      cogoToast.error("User has not uploaded CV");
    }
  };
  const onClickDownloadAll = () => {
    selectedRows
      .map((s) => s.values[`docs.cv_doc.file`])
      .map((value, i) => {
        const response = {
          file: value,
        };
        return setTimeout(() => {
          window.location.href = response.file;
        }, i * 1000);
      });
  };

  const userColumns = useMemo(
    () => [
      {
        accessor: "2",
        width: 10,
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        width: 100,
      },
      {
        Header: "Email",
        accessor: "email",
        width: 200,
      },
      {
        Header: "I am",
        accessor: "mentorType",
        width: 80,
      },
      {
        Header: "Industry",
        accessor: "industry",
        width: 120,
      },
      {
        Header: "Field of Work/ Study",
        accessor: "field_of_work",
        width: 120,
      },
      {
        Header: "CV",
        accessor: "docs.cv_doc.file",
        Cell: function cv({ row }) {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                colorScheme={"blue"}
                onClick={() => onClickDownload(row)}
                isDisabled={isUndefined(row.values[`docs.cv_doc.file`])}
              >
                Download CV
              </Button>
            </div>
          );
        },
        width: 140,
      },
      {
        Header: "Approve Applicant",
        accessor: "mentor_status",
        Cell: function approve({ row }) {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {loading ? (
                <Spinner />
              ) : (
                <Button
                  colorScheme={"blue"}
                  onClick={() => approveApplicant(row)}
                >
                  Approve
                </Button>
              )}
            </div>
          );
        },
        width: 100,
      },
      {
        Header: "Reject Applicant",
        accessor: "reject_applicant",
        Cell: function reject({ row }) {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {loading ? (
                <Spinner />
              ) : (
                <Button
                  colorScheme={"red"}
                  onClick={() => rejectApplicant(row)}
                >
                  Reject
                </Button>
              )}
            </div>
          );
        },
        width: 80,
      },
      {
        Header: "Applied At",
        accessor: "mentor.applied_at",
        sortType: (a, b) => {
          var a1 = new Date(a).getTime();
          var b1 = new Date(b).getTime();
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        },
        Cell: function status({ row }) {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {loading ? (
                <Spinner />
              ) : (
                <Text>
                  {!isUndefined(row.values[`mentor.applied_at`]) &&
                  !isNull(row.values[`mentor.applied_at`])
                    ? `${new Date(
                        row.values[`mentor.applied_at`]
                      ).toLocaleDateString()}`
                    : null}
                </Text>
              )}
            </div>
          );
        },
        width: 70,
      },
      {
        Header: "Rejected At",
        accessor: "mentor.rejected_at",
        Cell: function status({ row }) {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {loading ? (
                <Spinner />
              ) : (
                <Text>{row.values[`mentor.rejected_at`]}</Text>
              )}
            </div>
          );
        },
        width: 80,
      },
    ],
    [loading]
  );
  const getData = async () => {
    setPageLoad(true);
    try {
      const data = { page, size };
      const { applicants } = await getAppliedUsers(data);
      console.log(applicants);
      setTotal(applicants.total);
      setPages(applicants.pages);
      setPage(page);
      setSize(applicants.limit);
      setStats(applicants.docs);
      // setStats(res.filter((r) => r.mentor_status === 1));
    } catch (err) {
      console.log(err);
    }
    setPageLoad(false);
  };

  useEffect(() => {
    getData();
  }, [loading]);

  useEffect(() => {
    getData();
  }, [page, size]);

  const headers = [
    { label: "Name", key: "first_name" },
    { label: "Email", key: "email" },
    { label: "Date of birth", key: "dob" },
    { label: "Contact Number", key: "contact_number" },
    { label: "College Name", key: "college_name" },
    { label: "Degree", key: "degree" },
    { label: "Speacialization", key: "specialization" },
    { label: "Year of completion", key: "yoc" },
    { label: "Company", key: "company" },
    { label: "Current Location", key: "current_location" },
    { label: "Ethnicity", key: "ethnicity" },
    { label: "Any Disabilities", key: "disabilities" },
    { label: "Nationality", key: "nationality" },
    { label: "Vedio Analysis Score", key: "va_score" },
  ];

  const csvReport = {
    filename: `Applicants-${new Date().toDateString()}.csv`,
    headers: headers,
    data: stats,
  };

  return (
    <div>
      {pageLoad ? (
        <Spinner />
      ) : (
        <>
          <HStack isInline align={"end"} justify={"end"}>
            <Button
              size="lg"
              colorScheme="blue"
              leftIcon={<RepeatIcon />}
              mt="24px"
              onClick={getData}
            >
              Reload List
            </Button>
            <Button
              size="lg"
              colorScheme="blue"
              leftIcon={<AddIcon />}
              mt="24px"
              onClick={onOpen}
            >
              Add Applicant
            </Button>
            <ApplicantDrawer
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
            />
            <Button
              size="lg"
              colorScheme="blue"
              mt="24px"
              isDisabled={selectedRows.length === 0}
              onClick={onClickDownloadAll}
            >
              Download CV's
            </Button>
            <Button size="lg" colorScheme="green" mt="24px">
              <CSVLink {...csvReport}>Download CSV</CSVLink>
            </Button>
          </HStack>
          {/* <Box> */}

          <Styles>
            <Tables
              sortBy={"mentor.applied_at"}
              data={stats}
              columns={userColumns}
              onRowSelect={(rows) => setSelectedRows(rows)}
              page={page}
              setPage={setPage}
              size={size}
              setSize={setSize}
              total={total}
              pages={pages}
              setPages={setPages}
              getData={getData}
              searchFor={1}
              setData={setStats}
            />
            {/* <TestTable
                columns={userColumns}
                data={stats}
                onRowSelect={(rows) => setSelectedRows(rows)}
              /> */}
            {/* {
                <Table data={stats} columns={userColumns} page={page} setPage={setPage} size={size} setSize={setSize} total={total} pages={pages} setPages={setPages}/>
              } */}
          </Styles>

          {/* </Box> */}
        </>
      )}
    </div>
  );
};

export default ApplicantView;
