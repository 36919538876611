import axios from 'axios';
import { API_SERVER, UPLOAD_SERVER, BACKEND_SERVER } from '../helpers/constants';

const includeAuth = true;

export const getUsers = (params) =>
  axios.get(`${API_SERVER}/users`, { includeAuth, params });

  export const getUsersDetails = ({page , size}) =>
  axios.get(`${API_SERVER}/users/getAll?page=${page}&size=${size}`, { includeAuth});

  export const getAppliedUsers = ({page , size}) =>
  axios.get(`${API_SERVER}/users/applicants?page=${page}&size=${size}`, { includeAuth});

  export const getRejectedUsers = ({page , size}) =>
  axios.get(`${API_SERVER}/users/rejects?page=${page}&size=${size}`, { includeAuth});

export const findUserByEmail = (body) =>
  axios.post(`${API_SERVER}/users/findOne`, body);

export const uploadCV = (body) => axios.post(`${UPLOAD_SERVER}/upload`, body);

export const createApplicant = (body) =>
  axios.post(`${BACKEND_SERVER}/addData`, body);

export const getDashboardCount = (params) =>
  axios.get(`${API_SERVER}/users/count`, {includeAuth , params});
