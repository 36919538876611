import axios from 'axios';
import { API_SERVER, BACKEND_SERVER } from '../helpers/constants';

export const mentorAccess = (body) => axios.post(`${API_SERVER}/mentoraccess`, body);

export const getMentors = ({page , size}) =>
axios.get(`${API_SERVER}/users/mentors?page=${page}&size=${size}`);

export const disableMentor = (body) =>
  axios.post(`${API_SERVER}/disablementor`, body);

export const rejectMentor = (body) => axios.post(`${API_SERVER}/mentorreject`, body);

export const deleteMentorApplicantAPI = (body) => axios.delete(`${BACKEND_SERVER}/deleteuser`, body)

export const acceptMentor = (body) => axios.post(`${API_SERVER}/mentoraccept`, body);

export const emailMentor = (body) =>
  axios.post(`${API_SERVER}/mentoremailsend`, body);

export const getAllMentors = (params) =>
  axios.get(`${API_SERVER}/mentors`, { params });

export const generateDashboardToken = (body) =>
  axios.post(`${BACKEND_SERVER}/mentors/dashboard/token`, body);


